import { getRoutePathByItem } from "@constants";

// don't change THUMBNAILS name/type, it's used by `pages-loader` webpack loader
// see scripts/webpack/loaders/pages-loader.js
// prototype: device/name => thumbnail
const Campaign_THUMBNAILS = {
  "desktop/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEC/8QAGxABAQACAwEAAAAAAAAAAAAAAQAEEQIVMWL/xAAVAQEBAAAAAAAAAAAAAAAAAAACA//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oADAMBAAIRAxEAPwCmcjpKufyBdeSUqox2fzJJQa//2Q==",
  "desktop/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EAB0QAAICAgMBAAAAAAAAAAAAAAACAQMEESExUUH/xAAUAQEAAAAAAAAAAAAAAAAAAAAB/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A0X2Lc2q200fOiOHktZYsNOp5Vo96AArlyUSWj2dgAQ//2Q==",
  "desktop/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAQF/8QAGxAAAgMBAQEAAAAAAAAAAAAAAAECERIDIWH/xAAUAQEAAAAAAAAAAAAAAAAAAAAC/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAEh/9oADAMBAAIRAxEAPwDOhmvU7spU+LilLTaADYUuJemdvN19AAhf/9k=",
  "desktop/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAQMEAv/EACMQAAIBAwMEAwAAAAAAAAAAAAECEQADEhMhMQRBYXFCkaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABgRAQEAAwAAAAAAAAAAAAAAAAARAQJB/9oADAMBAAIRAxEAPwCXpby2gRoZTyQaBr6TuCk247Fdv2omt6lYZRIB8gc1Vas27oWDK+ln7osFzeT5K3jGhCsc95UeqGX/2Q==",
  "desktop/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAMB/8QAIBAAAQQBBAMAAAAAAAAAAAAAAQACAxExBBIhQRNCUf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAMAwEAAhEDEQA/AHukLAXVgINh1bJLoHKCnnHwoATznluxw6sjKiwaCQxg2Ob6tFio1JPq4Ikf/9k=",
  "desktop/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAQMEAv/EACMQAAIBAwMEAwAAAAAAAAAAAAECEQADEhMhMQRBYXFCkaH/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/EABgRAQEAAwAAAAAAAAAAAAAAAAARAQJB/9oADAMBAAIRAxEAPwCXpby2gRoZTyQaBr6TuCk247Fdv2omt6lYZRIB8gc1Vas27oWDK+ln7osFzeT5K3jGhCsc95UeqGX/2Q==",
  "desktop/bottom-4": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDASIAAhEBAxEB/8QAGAABAQADAAAAAAAAAAAAAAAAAAECAwT/xAAeEAACAgEFAQAAAAAAAAAAAAAAAQIRIRIxMkGBkf/EABUBAQEAAAAAAAAAAAAAAAAAAAAD/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A4VFtXa9ZdDveP0kW1hOrM5Sklzi89BVreGAAAAAAAD//2Q==",
  "mobile/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIEAf/EACQQAAIBBAECBwAAAAAAAAAAAAECAAMREiEEMUETIkJRYZGx/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/8QAGREBAQEAAwAAAAAAAAAAAAAAAAERAjFR/9oADAMBAAIRAxEAPwCDUpsAFq7Ue2yJajxGuqtYH1d5mp1uPTwslzbeQmnzBThmSehUG35MOWzqtZlS1QBrYt9RIqckI2LNu29RKm+0OVRZGHbHpMQdwhAZgLjV4iWlJJJuTc/MREQ//9k=",
  "mobile/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwT/xAAmEAABAwIFAwUAAAAAAAAAAAABAAIDESEEEiJBURMUMWJxkbHw/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/8QAGxEBAAEFAQAAAAAAAAAAAAAAAAECAxESMUH/2gAMAwEAAhEDEQA/AJILoC3etqq+OkoIw0+RX98LF4lbEC5pt6ljI7NlDjqAtwplsKFjiSa1Psi6qRkDVtsSim0LrK2LJuuScntmX4+kRC36VaYSei26IiE9OOP/2Q==",
  "mobile/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAASABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMEAgX/xAAgEAABBAEEAwAAAAAAAAAAAAABAAIDEQQSIUFRMUJx/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMA/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwCDJC5wvgUt0stxwudu4tXm44dI8AGgDuVryAA1ujYt44U8O2amyQ2/WfY18RSMleQiOFsVxxULa6XUxOirRFRJmBPaIizP/9k=",
  "mobile/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMCBP/EACIQAAIBAgUFAAAAAAAAAAAAAAECAAMEBRETIUEiMlFikf/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/EABYRAQEBAAAAAAAAAAAAAAAAAAASAf/aAAwDAQACEQMRAD8ANiVYqWWiFA5MyLu5qDPtHqu8B9wBxK6hK+IKKXPq3ROfX9iXasAcjEytUY//2Q==",
  "mobile/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUCAwQG/8QAIhAAAQQBAgcAAAAAAAAAAAAAAQACAxEhBBMxM0FRUoGh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREiH/2gAMAwEAAhEDEQA/ANzNTB516Vjp4DjdC55kobRIsjupGdxcM8MLNY4OTNp75v1CSGYnoChXTER//9k=",
  "mobile/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEASABIAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAhEAACAQMDBQAAAAAAAAAAAAABAgADBEEREjEUISJRUv/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAMAwEAAhEDEQA/AJ2t/oQp7jM2Jdo2dItFFXQsBtbJEr3vS8W4PBkobdTT+hCKwwPuEtI//9k="
};

const getThumbnailByKey = imgPath => {
  const key = Object.keys(Campaign_THUMBNAILS).find(
    k => imgPath.indexOf(k) !== -1
  );

  return Campaign_THUMBNAILS[key] || false;
};

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const extractImage = src => {
    const re = new RegExp(`^v(\\d+)\\/${imgParams.cloudinary.path}\\/(.+)$`);

    const m = re.exec(src) || {};

    return { src: m[2], version: m[1] };
  };

  const buildItem = (item, props = {}, loading = null) => ({
    img: {
      ...extractImage(item.IMG || ""),
      thumbnail: getThumbnailByKey(item.IMG),
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      loading,
      cloudinary: imgParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const filterItem = (item, defaultValue = true) =>
    "undefined" === typeof item.ENABLED ? defaultValue : Boolean(item.ENABLED);

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const sizes = {
    carousel: {
      mobile: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1207
        },
        aspect: 0.9149
      },
      desktop: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1424
        },
        aspect: 0.3581
      }
    },
    bottomImg: {
      mobile: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 298
        },
        aspect: 0.959
      },
      desktop: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 350
        },
        aspect: 0.7982
      }
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const images = ["carousel", "bottomImg"].reduce((carry, key) => {
    return Object.assign(carry, {
      [key]: ["mobile", "desktop"].reduce((carry, device) => {
        return Object.assign(carry, {
          [device]: _i18n[key][device]
            .filter(item => filterItem(item))
            .map((item, index) =>
              buildItem(
                item,
                sizes[key][device],
                key === "bottomImg" || !index ? "eager" : null
              )
            )
        });
      }, {})
    });
  }, {});

  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    ...images,
    header,
    buttons
  };
};
